.cart-item {
  width: 100%;
  display: flex;
  height: 80px;
  min-height: 80px;
  margin-bottom: 15px;
  border-bottom: 1px dashed darkgrey;
  &__image {
    width: 30%;
    max-width: 70px;
    height: 80px;
  }

  &__details {
    width: 70%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 10px 20px;

    &__name {
      font-size: 16px;
    }
  }
}
