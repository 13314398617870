.side-drawer {
  height: 100%;
  background: white;
  position: fixed;
  left: 0;
  width: 100vw;
  z-index: 200;
  transform: translateX(-100%);
  transition: transform 0.3s ease-out;
}
.side-options {
  padding: 0;
  margin: 0;
  &__link {
    padding: 17.5px 15px;
    cursor: pointer;
    letter-spacing: 1px;
    color: #f18cb5;
    text-decoration: none;
    display: block;
    position: relative;
    border-bottom: 1px solid #ccc;
    font-size: 1rem;
    text-align: center;
    line-height: 1.2rem;
    font-weight: 500;
    &:hover {
      color: #222;
    }
  }
}
.open {
  transform: translateX(0);
}

@media only screen and (min-width: 1000px) {
  .side-drawer {
    display: none;
  }
}
