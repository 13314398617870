.notice {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #333;
  text-transform: uppercase;
  padding: 0.2rem;
  h3 {
    color: #fff;
    font-size: 1rem;
    text-align: center;
    line-height: 1.2rem;
  }
  .keyword {
    color: #f18cb5;
  }
}

@media only screen and (min-width: 600px) {
  .notice {
    padding: 0;
    h3 {
      font-size: 1rem;
    }
  }
}
