.category {
  display: flex;
  flex-direction: column;
  background: #fff;
  color: #333;
  padding: 2rem;
  &__title {
    margin: 1.25rem 0rem;
    text-align: center;
    text-transform: uppercase;
    text-decoration: none;
    font-weight: 900;
    letter-spacing: 1px;
    line-height: 2.5rem;
    font-size: 2.25rem;
  }
  &__items {
    justify-content: space-between;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex-wrap: wrap;
    padding: 2rem 0rem;

    & .collection-item {
      margin-bottom: 30px;
    }
  }

  a {
    color: #333;
  }
}
