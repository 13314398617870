$sub-color: #ccc;
$main-color: #222;

@mixin shrinkLabel {
  top: -10px;
  font-size: 12px;
  color: $main-color;
}

.text {
  position: relative;
  display: flex;
  margin: 0.5rem 0rem;
  &__input {
    background: none;
    color: $main-color;
    font-size: 1.5rem;
    padding: 10px 10px 10px 5px;
    display: block;
    width: 100%;
    border: none;
    border-radius: 0.7rem;
    border: 1px solid $sub-color;
    margin: 25px 0;

    &:focus {
      outline: none;
    }

    &:focus ~ .text__input__label {
      @include shrinkLabel();
    }

    &__label {
      color: $main-color;
      font-size: 16px;
      font-weight: normal;
      position: absolute;
      pointer-events: none;
      left: 1rem;
      top: 40px;
      transition: 300ms ease all;

      &.shrink {
        @include shrinkLabel();
      }
    }
  }

  input[type="password"] {
    letter-spacing: 0.3em;
  }
}
