.checkout {
  background: #fff;
  color: #333;
  padding: 2rem 0rem;
  text-align: center;
  &__title {
    margin: 1.25rem 0rem;
    text-align: center;
    text-transform: uppercase;
    text-decoration: none;
    font-weight: 900;
    letter-spacing: 1px;
    line-height: 2.5rem;
    font-size: 2.25rem;
  }
  span {
    a {
      color: #333;
    }
  }
  &__wrapper {
    width: 100%;
    min-height: 98vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;

    padding: 1.5rem 0rem;
    .checkout-header {
      display: none;

      .header-block {
        text-transform: capitalize;
        width: 20%;
        text-align: Center;
      }
    }
    .cart-items {
      border-top: 1px dashed darkgrey;
      margin-top: 2rem;
    }
    .date-time__title {
      margin: 1.25rem 0rem;
      text-align: center;
      text-transform: uppercase;
      text-decoration: none;
      font-weight: 900;
      letter-spacing: 1px;
      line-height: 1.8rem;
      font-size: 1.6rem;
    }
    .delivery {
      box-shadow: 0px 0px 1px 0px #000;
      &__title {
        padding: 0.75rem 1rem;
        font-size: 1rem;
        border-bottom: 1px dashed;
        background: #f18cb5;
        color: #fff;
      }
      &__wrapper {
        padding: 0rem 1rem;
        border-bottom: 1px solid darkgrey;
      }
      &__date,
      &__time {
        text-align: left;
        margin: 1rem 0rem;
        .label {
          margin: 0.25rem 0rem;
        }
      }
      .DayPicker-wrapper {
        padding-bottom: 0;
        .DayPicker-Month {
          width: 100%;
          border-bottom: 1px solid darkgrey;
          margin: 0em 0 1.5rem 0;
          .DayPicker-Day {
            border-radius: 10%;
          }
        }
      }
      &__title {
        margin: 0 0 1.2em 0;
      }
    }
    .dt-wrapper {
      width: 100%;
      margin: 0 0 3rem 0;
    }
    .dt-wrapper-hidden {
      display: none;
    }
  }
  .total {
    margin: 1.5rem 0rem;
    font-size: 1.8rem;
  }
}

@media only screen and (min-width: 1000px) {
  .checkout {
    background: linear-gradient(#ffecf4 50%, #f18cb5 50%);
    &__title {
      background: none;
    }
    &__wrapper {
      background: #fff;
      width: 80%;
      min-height: 90vh;
      margin: 1.5rem auto;
      border-radius: 1rem;
      padding: 1rem 1rem;
      border: 1px solid #ddd;
      .checkout-header {
        width: 100%;
        padding: 10px;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid darkgrey;
        background: #f18cb5;
        color: #fff;
      }

      .cart-items {
        border-top: none;
        margin-top: none;
      }
      .delivery {
        box-shadow: 0px 0px 0px 0px #000;
        &__title {
          margin: 0;
        }
        &__wrapper {
          display: flex;
          border: 1px solid darkgrey;
        }
        &__date,
        &__time {
          width: 50%;
          padding: 1rem 0.5rem;
        }
      }
    }
    .total {
      margin-left: auto;
    }
  }
}

.react-datepicker {
  border: 1px solid darkgrey !important;
  &-wrapper {
    width: 100%;
    input {
      width: 100% !important;
      padding: 0.5rem;
      border: 1px solid darkgrey;
    }
  }
  &-popper[data-placement^="bottom"],
  &__triangle {
    left: 20% !important;
    border-bottom-color: #f18cb5 !important;
  }
  &__navigation--next {
    border-left-color: #fff !important;
  }
  &__header {
    background-color: #f18cb5 !important;
    border-bottom: 1px solid #ffffff !important;
  }
  &__current-month {
    color: #fff !important;
  }
  &__day-name,
  &-time__header {
    color: #fff !important;
  }

  &__day--selected,
  &__time-list-item--selected {
    background: #f18cb5 !important;
    &:hover {
      background: #f18cb5 !important;
    }
  }
}

.StripeCheckout {
  margin: 3rem 0rem;
  background: #f18cb5 !important;
  color: #fff !important;
  span {
    background-image: none !important;
  }
}
