.subscribe {
  margin: 0 auto;
  padding: 0rem 2rem;
  &__title {
    margin: 1.25rem 0rem;
    text-align: center;
    text-transform: uppercase;
    text-decoration: none;
    font-weight: 900;
    letter-spacing: 1px;
    line-height: 2.5rem;
    font-size: 2.25rem;
  }
}

.newsletter {
  flex-direction: column;
  justify-content: space-between;
  margin-top: 1rem;
  display: flex;
  &__field {
    display: flex;
    flex-direction: row;
    ::placeholder {
      color: #fff;
    }
    .newsletter-email {
      width: 100%;
      max-width: 1000px;
      padding: 0.5rem 0rem 0.5rem 0rem;
      text-indent: 1rem;
      margin: 0 auto;
      grid-area: input;
      display: flex;
      border-bottom: 1px solid #fff;
      background: none;
      border-left: none;
      border-top: none;
      border-right: none;
      outline: none;
      color: #fff;
    }
  }
  &__button {
    margin: 0 auto;
    font-family: "Robato", sans-serif;
    cursor: pointer;
    background: #f18cb5;
    padding: 1rem 2rem;
    color: #fff;
    text-transform: uppercase;
    align-self: flex-end;
    font-weight: bold;
    margin-top: 1.3rem;
    border: none;
    &:hover {
      background: #fff;
      color: #f18cb5;
    }
  }
}

.sending,
.error,
.success {
  color: #fff;
  background: #f18cb5;
  max-width: 1000px;
  margin: 0 auto;
  padding: 20px;
  border-radius: 5px;

  a {
    color: #fff;
  }
}
