.contact {
  display: flex;
  flex-direction: column;
  &__title {
    margin: 1.25rem 0rem;
    text-align: center;
    text-transform: uppercase;
    text-decoration: none;
    font-weight: 900;
    letter-spacing: 1px;
    line-height: 2.5rem;
    font-size: 2.25rem;
  }
  span {
    a {
      color: #222;
      text-decoration: underline;
    }
  }
  &__form {
    background: #fff;
    padding: 1.5rem 2rem;
    margin: 2rem 0rem;
    border-radius: 1rem;
    border: 1px solid #ddd;

    &__message {
      display: none;

      &--appear {
        display: block;
        text-align: center;
        margin: 0 auto;
        margin-bottom: 10px;
        width: 340px;
        padding: 3px;
        font-size: 1rem;
        font-weight: bold;
        color: #f18cb5;
      }
    }
  }
}

@media only screen and (min-width: 1000px) {
  .contact {
    &__form {
      width: 80%;
      margin: 2rem auto;
    }
  }
}
