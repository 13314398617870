.homepage {
  background: #ffdfea;
}

.subscribed {
  padding: 2rem 0rem 2rem 0rem;
  background: #333;
  color: #ffffff;
  display: flex;
  flex-direction: column;
}
