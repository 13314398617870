.cart-icon {
  display: flex;
  align-items: center;
  text-align: center;
  font-family: National2CompressedWeb, "Helvetica Condensed", "Arial Narrow",
    sans-serif;
  line-height: 1.09091;
  color: #fff;
  padding: 1.32rem 1rem;
  border-left: 1px dashed #fff;
  cursor: pointer;
  &__bag {
    width: 28px;
    height: 28px;
  }

  &__count {
    position: absolute;
    font-size: 0.8rem;
    font-weight: bold;
    bottom: 24px;
    margin: 0 auto;
    width: 1.75rem;
  }
}

@media only screen and (min-width: 1000px) {
  .cart-icon {
    padding: 1.3rem 1.3rem;
    margin: 0rem 0rem 0rem 4rem;
    background: #f18cb5;
  }
}
