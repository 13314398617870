.header {
  position: sticky;
  top: 0;
  z-index: 10;
}
.navbar {
  height: 70px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  background: #f18cb5;
  align-items: center;
  box-shadow: 0px 0px 4px 0px #000;
}

@media only screen and (max-width: 999px) {
  .navbar {
    &__menu {
      display: none;
    }
    .logo {
      display: flex;
      justify-content: center;
      width: 70px;
      padding: 0.2rem;
      border: 1px dashed;
      border-radius: 2rem;
      box-shadow: 1px 1px 1px #333;
    }
    &__toggle-button {
      padding: 1.44rem 1rem;
      border-right: 1px dashed #fff;
    }
  }
}

@media only screen and (min-width: 1000px) {
  .navbar {
    &__toggle-button {
      display: none;
    }

    .logo {
      text-align: center;
      margin: 0 2rem;
      width: 70px;
      border: none;
      padding: 0.2rem;
      border: 1px dashed;
      border-radius: 2rem;
      box-shadow: 1px 1px 1px #333;
    }
    &__menu {
      list-style: none;
      padding: 0;
      margin: 0;
      display: flex;
      flex: 1 0 60vw;
      align-items: center;
      justify-content: center;
      .option {
        text-decoration: none;
        font-variant-ligatures: common-ligatures;
        font-feature-settings: "liga" 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-family: National2CompressedWeb, "Helvetica Condensed",
          "Arial Narrow", sans-serif;
        font-weight: 900;
        font-size: 0.95rem;
        font-style: normal;
        font-stretch: normal;
        text-transform: uppercase;
        line-height: 1.09091;
        letter-spacing: 0.02875em;
        color: #fff;
        padding: 0.75rem 2.5rem;
        cursor: pointer;
        border: 1px solid #f18cb5;
        border-radius: 0.3rem;
        margin: 0rem 0.5rem;
        &:hover {
          background: #fff;
          color: #f18cb5 !important;
          border: 1px solid #eee !important;
          filter: drop-shadow(1px 1px 1px #333);
        }
      }
    }
  }
}

.is-active {
  background: #fff;
  color: #f18cb5 !important;
  border: 1px solid #eee !important;
  filter: drop-shadow(1px 1px 1px #333);
}
