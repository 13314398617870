.faq {
  background: linear-gradient(#ffecf4 50%, #f18cb5 50%);
  color: #333;
  justify-content: space-between;
  text-align: center;
  padding: 1rem 1rem;
  &__title {
    margin: 20px 0px;
    text-align: center;
    text-transform: uppercase;
    text-decoration: none;
    font-weight: 900;
    letter-spacing: 1px;
    line-height: 40px;
    font-size: 2.2rem;
  }
  &__body {
    background: #fff;
    padding: 1.5rem 2rem;
    margin: 2rem 0rem;
    border-radius: 1rem;
    border: 1px solid #ddd;
    text-align: left;
    .cell {
      border: 1px dashed #ccc;
      padding: 1rem;
      margin-bottom: 1rem;
      border-radius: 1rem;
      &:hover {
        border: 1px solid #ccc;
      }
      p {
        a {
          color: #222;
          text-decoration: underline;
        }
      }
    }
  }
}

@media only screen and (min-width: 900px) {
  .faq {
    padding: 2rem 4rem;
  }
}

@media only screen and (min-width: 1000px) {
  .faq {
    &faq__body {
      width: 80%;
      margin: 2rem auto;
    }
  }
}
