.foot {
  display: flex;
  flex-direction: column;
  color: #f18cb5;
  padding: 2rem 0rem;
  margin: 0;
  &__social-group {
    display: flex;
    margin: 2rem 0rem;
    justify-content: center;
    .social {
      display: flex;
      align-items: center;
      list-style: none;
      padding: 1.5rem;
      background: #f18cb5;
      color: #fff;
      height: 1.5rem;
      font-size: 1.5rem;
      margin: 0rem 1rem;
      border-radius: 0.5rem;
      &:hover {
        background: #fff;
        color: #f18cb5;
      }
    }
  }

  .copyright {
    color: #ffffff;
    font-size: 1rem;
    margin: 0;
    text-align: center;
    padding: 0rem 1rem;
    text-transform: uppercase;
    text-decoration: none;
    font-weight: 900;
    letter-spacing: 1px;
    line-height: 40px;
  }

  &__link-group {
    display: flex;
    padding: 0rem 1rem 0rem 0rem;
    justify-content: center;
    flex-wrap: wrap;
    .foot-link {
      color: #ffffff;
      margin-bottom: 1rem;
      padding: 0rem 1rem;
      list-style: none;
      padding: 0.5rem;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
