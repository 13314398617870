.about {
  display: flex;
  flex-direction: column;

  &__featured-1 {
    height: 40rem;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
    padding: 2rem 1rem;
    image-rendering: -webkit-optimize-contrast;
    justify-content: center;
    width: 100%;
    filter: opacity(0.75);
  }
  &__featured-2 {
    height: 40rem;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
    padding: 2rem 1rem;
    image-rendering: -webkit-optimize-contrast;
    justify-content: center;
    width: 100%;
    filter: opacity(0.75);
  }
  &__featured-3 {
    height: 40rem;
    background-position: 0% 0%;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
    padding: 2rem 1rem;
    image-rendering: -webkit-optimize-contrast;
    justify-content: center;
    width: 100%;
    filter: opacity(0.75);
  }
  &__featured-4 {
    height: 40rem;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
    padding: 2rem 1rem;
    image-rendering: -webkit-optimize-contrast;
    justify-content: center;
    width: 100%;
    filter: opacity(0.75);
  }
}

h1.about-text {
  font-size: 4.5rem;
  color: #fff;
  width: 100%;
  text-transform: uppercase;
  text-decoration: none;
  font-weight: 900;
  letter-spacing: 1px;
  line-height: 4rem;
  text-align: center;
  filter: drop-shadow(2px 4px 6px black);
}

@media only screen and (min-width: 1000px) {
  .about {
    background: #f18cb580;
    flex-direction: row;
    &__featured-1,
    &__featured-2,
    &__featured-3,
    &__featured-4 {
      width: 50%;
    }
  }
}
