.checkout-item {
  width: 100%;
  display: flex;
  min-height: 100px;
  border-bottom: 1px dashed darkgrey;
  align-items: center;

  &__image-container {
    width: 45%;
    padding: 15px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  &__name,
  &__quantity,
  &__price {
    width: 20%;
    padding: 0rem 0.75rem;
    display: flex;
    justify-content: center;
  }

  &__quantity {
    display: flex;
    .arrow {
      cursor: pointer;
    }
    .value {
      margin: 0 10px;
    }
  }

  &__remove-button {
    text-align: center;
    cursor: pointer;
    width: 20%;
    padding: 0rem 0.75rem;
    display: flex;
    justify-content: center;
  }
}

@media only screen and (min-width: 500px) {
  .checkout-item {
    font-size: 20px;
    &__image-container {
      width: 30%;
    }
  }
}

@media only screen and (min-width: 800px) {
  .checkout-item {
    font-size: 20px;
    &__image-container {
      width: 20%;
    }
  }
}
