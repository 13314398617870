.item {
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  align-items: center;
  position: relative;

  &__image {
    width: 100%;
    height: 200px;
    background-size: cover;
    background-position: center;
    margin-bottom: 5px;
  }

  &__footer {
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-size: 18px;
    font-weight: 600;

    .name {
      width: 70%;
      margin-bottom: 15px;
    }

    .price {
      width: 30%;
    }
  }
  &__description {
    width: 100%;
  }
  .button {
    width: 100%;
  }
}

@media only screen and (min-width: 768px) {
  .item {
    width: 48%;
    &__footer {
      flex-direction: row;
      .price {
        display: flex;
        justify-content: flex-end;
      }
    }
  }
}

@media only screen and (min-width: 1025px) {
  .item {
    width: 24%;
    &__footer {
      height: 2rem;
      .name {
        width: 60%;
        margin-bottom: 15px;
      }

      .price {
        width: 40%;
      }
    }
  }
}

@media only screen and (min-width: 1200px) {
  .item {
    width: 24%;
    &__footer {
      height: 2rem;
      .name {
        width: 70%;
        margin-bottom: 15px;
      }

      .price {
        width: 30%;
      }
    }
  }
}
