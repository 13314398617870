.overview {
  &__cake-pops,
  &__misc {
    background: #fff3f8;
    color: #333;
    padding: 1.85rem 0rem;
  }
  &__cookies {
    background: #f18cb5;
    color: #fff;
    padding: 1.85rem 0rem;
  }
}
