.contact-page {
  background: linear-gradient(#ffecf4 50%, #f18cb5 50%);
  color: #333;

  justify-content: space-between;
  text-align: center;
  padding: 1rem 1rem;
}

@media only screen and (min-width: 900px) {
  .contact-page {
    padding: 2rem 4rem;
  }
}
