.button {
  font-family: "Robato", sans-serif;
  cursor: pointer;
  transition: transform 0.3s ease;
  padding: 1rem 2rem;
  text-transform: uppercase;
  align-self: flex-end;
  font-weight: bold;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  border: none;
  &--one {
    background: #f18cb5;
    color: #fff;
    border: 1px solid #f18cb5;

    &:hover {
      background: rgba(255, 255, 255, 0);
      color: #fff;
      border: 1px solid #fff;
    }
  }
  &--two {
    background: #333;
    color: #fff3f8;
    border: 1px solid #333;
    &:hover {
      background: #fff3f8;
      color: #333;
    }
  }
  &--three {
    background: #fff;
    color: #f18cb5;
    border: 1px solid #fff;
    &:hover {
      background: #f18cb5;
      color: #fff;
    }
  }
  &--cart {
    background: #333;
    color: #fff;
    border: 1px solid #333;
    &:hover {
      background: #fff;
      color: #333;
    }
  }

  &--google {
    background: #4c8bf5;
    color: #fff;
    border: 1px solid #4c8bf5;
    &:hover {
      background: #fff;
      color: #4c8bf5;
    }
  }

  &--four {
    background: #333;
    color: #fff;
    border: 1px solid #333;
    &:hover {
      color: #333;
      background: none;
    }
  }
  &--contact {
    background: #f18cb5;
    color: #fff;
    border: 1px solid #f18cb5;
    &:hover {
      background: #fff;
      color: #f18cb5;
    }
  }
  &--featured {
    background: #f18cb5;
    color: #fff;
    border: 1px solid #f18cb5;
    &:hover {
      background: #fff;
      color: #f18cb5;
      border: 1px solid #fff;
    }
  }
  &--user {
    background: #222;
    color: #fff;
    border: 1px solid #222;
    &:hover {
      background: #fff;
      color: #222;
    }
  }
}
