.cart {
  display: flex;
  flex-direction: column;
  padding: 20px;
  color: #333;
  height: 340px;
  background: white;
  position: absolute;
  right: 0;
  width: 100vw;
  z-index: 200;

  filter: none;
  border-top: 1px solid #a9a9a9;
  border-bottom: 1px solid #a9a9a9;
  box-shadow: 0px 2px 2px #a9a9a9;

  &__items {
    display: flex;
    flex-direction: column;
    overflow: scroll;
    min-height: 75%;
  }

  &__message {
    font-size: 1.2rem;
    margin: 3rem auto;
  }

  button {
    justify-content: flex-end;
    margin-bottom: 0;
    width: 100%;
  }
}

@media only screen and (min-width: 610px) {
  .cart {
    width: 50vw;
    height: 50vw;
    border: 1px solid darkgrey;
    margin-top: 10px;
  }
}

@media only screen and (min-width: 1000px) {
  .cart {
    width: 33vw;
    height: 33vw;
    border: 1px solid darkgrey;
    &__items {
      min-height: 80%;
    }
  }
}

@media only screen and (min-width: 1340px) {
  .cart {
    &__items {
      min-height: 85%;
    }
  }
}
