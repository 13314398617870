.description {
  background: #333333;
  color: #fff;
  text-align: center;
  padding: 1rem;
  border-top: 1px solid #fff;
}
.featured {
  display: flex;
  flex-direction: column;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  &__item-1 {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    .image {
      width: 100%;
      background-size: cover;
      background-position: center;
      image-rendering: -webkit-optimize-contrast;
      height: 22rem;
    }
    .rev-image {
      width: 100%;
      background-size: cover;
      background-position: center;
      image-rendering: -webkit-optimize-contrast;
      height: 22rem;
      transform: scaleX(-1);
      filter: FlipH;
      -ms-filter: "FlipH";
      float: none;
    }
    .button {
      width: 80%;
      display: flex;
      justify-content: center;
      position: absolute;
      top: 75%;
      right: 10%;
    }
  }
  &__item-2,
  &__item-3 {
    display: none;
  }
  .middle {
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;
  }
}

@media only screen and (min-width: 768px) {
  .featured {
    display: flex;
    flex-direction: row;
    &__item-1 {
      width: 50%;
      .image {
        height: 35rem;
      }
      .rev-image {
        height: 35rem;
      }
    }
    &__item-2,
    &__item-3 {
      width: 50%;
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;

      .image {
        width: 100%;
        background-size: cover;
        background-position: center;
        image-rendering: -webkit-optimize-contrast;
        height: 35rem;
      }
      .rev-image {
        width: 100%;
        background-size: cover;
        background-position: center;
        image-rendering: -webkit-optimize-contrast;
        height: 35rem;
        transform: scaleX(-1);
        filter: FlipH;
        -ms-filter: "FlipH";
        float: none;
      }
      .button {
        width: 80%;
        display: flex;
        justify-content: center;
        position: absolute;
        top: 75%;
        right: 10%;
      }
    }
    &__text {
      width: 50%;
      .title {
        font-size: 3rem;
        line-height: 3rem;
        margin: 2rem 0rem;
      }
      .price,
      .description {
        margin: 2rem 0rem;
      }
    }
  }
}
