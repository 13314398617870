.featured-hero {
  height: 36rem; // 40 rem for small desktop
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  padding: 2rem 1rem;
  image-rendering: -webkit-optimize-contrast;
  justify-content: center;

  h1 {
    font-size: 3.5rem;
    color: #f18cb5;
    width: 100%;
    text-transform: uppercase;
    text-decoration: none;
    font-weight: 900;
    line-height: 4rem;
    filter: drop-shadow(2px 4px 6px black);
    span {
      color: #fff;
      font-size: 3rem;
    }
  }
  &__button {
    filter: none;
    padding: 1rem 0rem;
  }
}

/* Media Queries */
@media only screen and(min-width:350px) {
  .featured-hero {
    &__button {
      padding: 2rem 0rem;
    }
  }
}

@media only screen and(min-width:360px) {
  .featured-hero {
    h1 {
      text-align: center;
    }
  }
}

@media only screen and(min-width:634px) {
  .featured-hero {
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.7)),
      url(../../assets/img/featured.jpg);
    background-position: 10% 30%;
    h1 {
      font-size: 6rem;
      text-align: center;
    }
  }
}

@media only screen and(min-width:1000px) {
  .featured-hero {
    height: 45rem;
    background-attachment: fixed;
    background-position: 0% 10%;
    h1 {
      font-size: 8rem;
      text-align: center;
    }
  }
}
