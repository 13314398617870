.sign-in-and-sign-up {
  background: linear-gradient(
    to right,
    rgba(76, 139, 245, 0) 50%,
    rgba(255, 236, 244, 0.47) 50%
  );
  color: #333;
  display: flex;
  justify-content: space-between;
  text-align: center;
  flex-direction: column;
}

@media only screen and (min-width: 1000px) {
  .sign-in-and-sign-up {
    flex-direction: row;
    background: linear-gradient(
      to right,
      rgba(76, 139, 245, 0) 50%,
      rgba(255, 236, 244, 0.47) 50%
    );
  }
}
