.sign-up {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 2rem;

  form {
    padding: 2rem 0rem;
  }
  &__title {
    margin: 1.25rem 0rem;
    text-align: center;
    text-transform: uppercase;
    text-decoration: none;
    font-weight: 900;
    letter-spacing: 1px;
    line-height: 2.5rem;
    font-size: 2.25rem;
  }
}

.full {
  width: 100%;
}

@media only screen and (min-width: 1000px) {
  .sign-up {
    width: 50%;
  }
}
